import {FC, useEffect, useState, useRef} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {ParentCategoryModel} from '../../pages/shop/profile/ProfileModels'
import {getShopCategories} from '../../pages/shop/profile/_requests'
import {getSearches, getVipShops} from './_requests'
import {ProductModel, ShopModel} from './Models'
import {Products} from './components/productCard'
import {useAuth} from '../../modules/auth'
import {getUserByToken} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'


const Index: FC = () => {
  const [categories, setCategories] = useState<ParentCategoryModel[]>([])
  const [vipProducts, setVipProducts] = useState<ProductModel[]>([])
  const [vipShops, setVipShops] = useState<ShopModel[]>([])
  const [latestProducts, setLatestProducts] = useState<ProductModel[]>([])
  const [popularProducts, setPopularProducts] = useState<ProductModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const didRequest = useRef(false)
  const {setCurrentUser, saveAuth} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        if (didRequest.current) return
        didRequest.current = true

        // بررسی وجود توکن در URL
        const params = new URLSearchParams(window.location.search)
        const token = params.get('token')

        if (token) {
          const tokenAuth = {api_token: token, status: true}
          saveAuth(tokenAuth)

          const {data: user} = await getUserByToken()
          if (user) {
            setCurrentUser(user)
            navigate('/dashboard', {replace: true})
          } else {
            console.error('خطا در دریافت اطلاعات کاربر با توکن.')
          }
        }
      } catch (error) {
        console.error('خطا در فرآیند لاگین:', error)
      }
    }

    const fetchCategories = async () => {
      try {
        const response = await getShopCategories()
        setCategories(response.data)
      } catch (error) {
        console.error('Failed to fetch categories:', error)
      }
    }

    const fetchVipShops = async () => {
      setLoading(true)
      setError(null)

      try {
        const response = await getVipShops()
        setVipShops(response.data)
      } catch (err) {
        setError('خطا در دریافت فروشگاه‌های ویژه')
      } finally {
        setLoading(false)
      }
    }

    const fetchVipProducts = async () => {
      setLoading(true)
      setError(null)

      try {
        const response = await getSearches('n=8&s=vip')
        setVipProducts(response.data)
      } catch (err) {
        setError('خطا در دریافت محصولات ویژه')
      } finally {
        setLoading(false)
      }
    }

    const fetchLatestProducts = async () => {
      setLoading(true)
      setError(null)

      try {
        // Directly sending parameters for fetching the latest products
        const response = await getSearches('n=8&s=newest')
        setLatestProducts(response.data)
      } catch (err) {
        setError('خطا در دریافت محصولات جدید')
      } finally {
        setLoading(false)
      }
    }

    const fetchPopularProducts = async () => {
      setLoading(true)
      setError(null)

      try {
        // Directly sending parameters for fetching the most popular products
        const response = await getSearches('n=8&s=mostPopular')
        setPopularProducts(response.data)
      } catch (err) {
        setError('خطا در دریافت محصولات محبوب')
      } finally {
        setLoading(false)
      }
    }

    initializeAuth()
    fetchCategories()
    fetchVipProducts()
    fetchVipShops()
    fetchLatestProducts()
    fetchPopularProducts()
  }, [saveAuth, setCurrentUser])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xxl-12'>
          <div className={`h-md-100`}>
            <div
              className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
              style={{
                backgroundPosition: '100% 50%',
                backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
              }}
            >
              <div className='text-center'>
                <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
                  <span className='me-2'>
                    دیجی نمایشگاه؛ شبکه اجتماعی صنوف
                    <br />
                    <span className='position-relative d-inline-block text-success'>
                      نمایشگاهی به وسعت ایران
                      <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
                    </span>
                  </span>
                </div>
                <div className='text-center'>
                  <Link to='/NewShop' className='btn fw-bold btn-primary'>
                    ساخت فروشگاه
                  </Link>
                  <Link to='/search' className='btn fw-bold btn-secondary me-5'>
                    جستجو در بازار
                  </Link>
                </div>
                <div className='text-center mt-3'>
                  <a
                    href='https://diginamayeshgah.com/diginamayeshgah.apk'
                    className='btn fw-bold btn-success'
                    target='_blank'
                  >
                    دانلود اپلیکیشن
                  </a>
                </div>
              </div>
              <img
                className='mx-auto h-250px h-lg-300px theme-light-show mb-10'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/11.png')}
                alt=''
              />
              <img
                className='mx-auto h-250px h-lg-300px theme-dark-show mb-10'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/11-dark.png')}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row - نمایش فروشگاه‌های ویژه */}
      {vipShops.length > 0 && (
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-5'>
          <div className='col-12'>
            <div className='d-flex flex-wrap justify-content-start'>
              {vipShops.map((shop) => (
                <div key={shop.id} className='text-center me-3 mb-3'>
                  <Link to={`/shop/${shop.username}`} className='text-decoration-none'>
                    <div className='symbol symbol-75px symbol-circle overflow-hidden'>
                      <img
                        src={shop.avatar || toAbsoluteUrl('/media/avatars/blank.png')}
                        alt={shop.name}
                        className='w-20 h-20'
                      />
                    </div>
                    <div className='fs-6 fw-bold mt-2 text-gray-800'>{shop.name}</div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* end::Row */}

      {/* begin::Row - نمایش محصولات ویژه */}
      {loading ? (
        <div>در حال بارگذاری...</div>
      ) : error ? (
        <div className='alert alert-danger'>{error}</div>
      ) : vipProducts.length === 0 ? (
        <></>
      ) : (
        <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-5'>
          <div className='col-12'>
            <h3 className='text-end mb-5'>
              <Link to='/search?s=vip'>محصولات ویژه</Link>
            </h3>
            <Products products={vipProducts} />
          </div>
        </div>
      )}
      {/* end::Row */}

      {/* begin::Row - نمایش دسته‌بندی‌ها */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {categories.map((category) => (
          <div className='col-md-2 col-6' key={category.id}>
            <div className='card h-100'>
              <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                <Link
                  to={`/search?c=${category.id}`}
                  className='text-gray-800 text-hover-primary d-flex flex-column'
                >
                  <div className='symbol symbol-75px mb-1'>
                    <KTIcon iconName={category.icon} className='landing-cfs' />
                  </div>
                  <div className='fs-6 mb-2'>{category.name}</div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* end::Row */}

      {/* begin::Row - نمایش جدیدترین محصولات */}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-5'>
        <div className='col-12'>
          <h3 className='text-end mb-5'>
            <Link to='/search?s=newest'>جدیدترین محصولات</Link>
          </h3>

          {loading ? (
            <div>در حال بارگذاری...</div>
          ) : error ? (
            <div className='alert alert-danger'>{error}</div>
          ) : latestProducts.length === 0 ? (
            <div className='alert alert-info'>محصولی جدید یافت نشد!</div>
          ) : (
            <div className='carousel-container'>
              <Products products={latestProducts} />
            </div>
          )}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row - نمایش محبوب ترین محصولات */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-5'>
        <div className='col-12'>
          <h3 className='text-end mb-5'>
            <Link to='/search?s=mostPopular'>محبوب‌ترین محصولات</Link>
          </h3>

          {loading ? (
            <div>در حال بارگذاری...</div>
          ) : error ? (
            <div className='alert alert-danger'>{error}</div>
          ) : popularProducts.length === 0 ? (
            <div className='alert alert-info'>محصولی محبوب یافت نشد!</div>
          ) : (
            <div className='carousel-container'>
              <Products products={popularProducts} />
            </div>
          )}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-5'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
          <h2 className='text-end mb-5'>برای تولید کنندگان</h2>
          <p className='fs-5'>
            اگر شما تولیدکننده هستید و به دنبال دیده‌شدن و عرضه مستقیم محصولات خود به مشتریان هستید،
            این پلتفرم فضای ایده‌آلی برای شماست. با ایجاد پروفایل، محصولات خود را معرفی کنید و به
            هزاران مشتری و خریدار دسترسی پیدا کنید. حضور شما، نخستین گام در ایجاد ارتباط‌های مؤثر و
            سودآور خواهد بود.{' '}
          </p>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/9.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/9-dark.png')}
            alt=''
          />
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/5.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/5-dark.png')}
            alt=''
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
          <h2 className='text-end mb-5'>برای بازرگانان</h2>
          <p className='fs-5'>
            بازرگانان عزیز، اینجا مکانی است که شما می‌توانید ارتباطات تجاری خود را گسترش دهید.
            محصولات و خدمات خود را به نمایش بگذارید، با تأمین‌کنندگان و مشتریان جدید همکاری کنید و
            با سرعت و کارایی بالا، شبکه تجاری خود را مدیریت نمایید.
          </p>
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
          <h2 className='text-end mb-5'>برای خدمات دهندگان</h2>
          <p className='fs-5'>
            ارائه‌دهندگان خدمات می‌توانند از این پلتفرم به عنوان یک ابزار قدرتمند استفاده کنند.
            خدمات خود را در دسته‌بندی‌های مختلف معرفی کنید و مشتریانی که دقیقاً به دنبال شما هستند
            را پیدا کنید. این فرصت ایده‌آلی برای رشد کسب‌وکار شماست.{' '}
          </p>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/17-dark.png')}
            alt=''
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/2.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/2-dark.png')}
            alt=''
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
          <h2 className='text-end mb-5'>برای عمده فروشان</h2>
          <p className='fs-5'>
            عمده‌فروشان می‌توانند به راحتی کالاهای خود را معرفی کرده و به خریداران بزرگ دسترسی پیدا
            کنند. ما بستر مناسبی را برای گسترش کسب‌وکار و افزایش فروش شما فراهم کرده‌ایم. کافی است
            محصولات خود را بارگذاری کنید و از این فرصت بهره‌مند شوید.{' '}
          </p>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row*/}
      <div className='row g-5 g-xl-10 mb-10 mb-xl-10 mt-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
          <h2 className='text-end mb-5'>برای خرده فروشان</h2>
          <p className='fs-5'>
            خرده‌فروشان عزیز، این پلتفرم به شما امکان می‌دهد تا محصولات خود را به راحتی در معرض دید
            خریداران قرار دهید. از این فرصت برای افزایش فروش و گسترش بازار هدف خود استفاده کنید و در
            کمترین زمان، نتایج مثبت را مشاهده کنید.
          </p>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 text-center'>
          <img
            className='mx-auto h-200px h-lg-250px theme-light-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/8.png')}
            alt=''
          />
          <img
            className='mx-auto h-200px h-lg-250px theme-dark-show mb-10'
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/8-dark.png')}
            alt=''
          />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export {Index}
